import React from 'react';
import { graphql } from 'gatsby';

import * as Core from 'components/core';
import Layout from 'components/departments/DepartmentLayout';

import Publications, {
  Props as PublicationsProps
} from 'components/in-print/Publications';

interface Props {
  data: PublicationsProps & {
    authorImage: Core.ImageAttributes;
    authorImageBook: Core.ImageAttributes;
  };
}

const DepartmentContent: React.FC<Props> = (props) => {
  const { publications, authorImage, authorImageBook } = props.data;

  return (
    <Layout departmentSlug="in-print" title="In Print">
      <Core.ContentWrapper>
        <Core.ContentSection id="q-and-a-with-author">
          <Core.DepartmentLabel>In Print</Core.DepartmentLabel>
          <Core.PrimaryHeading subHeading={<em>Beyond Silicon Valley</em>}>
            Michael Goldberg ’99
          </Core.PrimaryHeading>
        </Core.ContentSection>
        <Core.ContentSection
          marginalia={
            <>
              <Core.FullWidthImage
                image={authorImageBook}
                alt="Beyond Silicon Valley"
                contentSectionPlacement="marginalia"
                caption={
                  <>
                    <span>(Self-Published, 2018)</span>
                    <br />
                    <a
                      href="http://www.beyondsiliconvalleybook.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Download for free
                    </a>
                    <br />
                    <a
                      href="https://www.amazon.com/dp/B07CGSGJ7Y/ref=cm_sw_em_r_mt_dp_U_-KloCbQXZM96X/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Buy on Amazon
                    </a>
                  </>
                }
              />
              <Core.Caption>
                Michael E. Goldberg is a venture capitalist and assistant
                professor of entrepreneurship in the Department of Design and
                Innovation at the Weatherhead School of Management at Case
                Western Reserve University.
              </Core.Caption>
            </>
          }
        >
          <p>
            Michael Goldberg ’99 has built a global network of entrepreneurs on
            six continents, student by student, through his creation of a
            fantastically successful online course that shines a spotlight on
            the economic renaissance of Cleveland, Ohio.
          </p>
          <p>
            In his new book, <em>Beyond Silicon Valley</em>, he traces how the
            online seminar of the same name connected with international
            business students since it was listed on Coursera in 2013.
          </p>
          <p>
            The seminar’s articulation of key principles of entrepreneurship is
            the most translated massive open online course (aka MOOC) offered on
            Coursera, where students can take it in 16 languages, including
            Greek, Chinese, Persian, and Kinyarwanda, the language of Rwanda.
            Beyond Silicon Valley also remains among the most popular courses on
            the platform, with 175,000 students and counting.
          </p>

          <p>
            In <em>Beyond Silicon Valley</em>, Goldberg relates how the course
            was inspired by a request from a Vietnamese government official, who
            asked that Goldberg lead a seminar that might help his country
            become more like Silicon Valley.
          </p>
          <p>
            Goldberg, a venture capitalist, Case Western Reserve University
            entrepreneurship professor, and Cleveland native, replied that
            coursework on how entrepreneurial principles helped revive a scrappy
            Midwestern city might serve as a more useful example than the
            outsized booms and busts of Northern California.
          </p>
          <p>
            “There are other ways, and other models, that can provide more help
            for entrepreneurs than Silicon Valley,” Goldberg says. His own
            analysis of Cleveland’s collective nurture of entrepreneurship with
            government outreach, philanthropy, nongovernmental organizations,
            and anchor institutions, such as hospitals and universities, offered
            such lessons.
          </p>

          <p>
            While Cleveland may have been Goldberg’s inspiration,{' '}
            <em>Beyond Silicon Valley</em> looks far past the city on Lake Erie.
            The book tells the stories of students in Asia, Europe, South
            America, and Africa who took the course and found that they now
            share Goldberg’s vision that entrepreneurship can take hold anywhere
            in the right conditions.
          </p>
          <p>
            “The course has provided an amazing teaching and learning
            opportunity,” he says. That opportunity consists of more than
            imbibing the seminar’s concepts. The students featured in Goldberg’s
            book created energetic, in-person discussions (“meetups”) and
            translated the subtitles of the course into their own languages.
          </p>
          <p>
            Some 43 percent of students with strong engagement in Beyond Silicon
            Valley start a new business after taking the course, and Goldberg
            relates with relish how these budding entrepreneurs put the course’s
            teachings into action in their own communities—even in challenging
            environments, such as Venezuela, Botswana, and Kosovo.
          </p>
          <Core.FullWidthImage image={authorImage} alt="Michael Goldberg" />
          <p>
            “If you think it’s hard in Cleveland, some of these places are the
            next level of complicated,” observes Goldberg.
          </p>
        </Core.ContentSection>
        <Core.ContentSection
          marginalia={
            <Core.Blockquote color="beige">
              While Cleveland may have been Goldberg’s inspiration,{' '}
              <em>Beyond Silicon Valley</em> looks far past the city on Lake
              Erie. The book tells the stories of students in Asia, Europe,
              South America, and Africa who took [his] course and found that
              they now share Goldberg’s vision that entrepreneurship can take
              hold anywhere in the right conditions.
            </Core.Blockquote>
          }
        >
          <p>
            The global success described in <em>Beyond Silicon Valley</em>{' '}
            extends a focus on international affairs that has been a strong
            narrative in Goldberg’s career. He worked in the international
            development sector in South Africa with WorldTeach and the National
            Democratic Institute in the early 1990s, after graduating from
            Princeton University. Goldberg also joined AOL in 1999, working as
            director of the company’s international business development before
            leaving in 2003 to return to his hometown.
          </p>
          <p>
            In between South Africa and AOL, however, Goldberg earned an MA in
            International Studies at SAIS in 1999, in concert with an MBA from
            The Wharton School at the University of Pennsylvania in 1998.
            Because he completed the MBA first, Goldberg recalls that he was
            branded as a “Wharton person” when he first arrived at the
            Washington, D.C., campus. “No, no, I’d tell them. I’m a SAIS
            person,” he recalls.
          </p>
          <p>
            He adds that his studies at SAIS made a strong imprint that help
            shaped the objectives of the course. “SAIS attracts students who
            want to make a difference in the world,” he says. “This project
            couldn’t be any more aligned with that.”
          </p>

          <Core.SignOff>Richard Byrne</Core.SignOff>
          <Core.Share id="q-and-a-with-author" />
        </Core.ContentSection>

        <Core.HorizontalRule />

        <Core.Flag>Published</Core.Flag>
        <Publications publications={publications} />
      </Core.ContentWrapper>
    </Layout>
  );
};

export const query = graphql`
  query($directory: String!) {
    publications: allFile(
      filter: {
        relativePath: { regex: $directory }
        name: { eq: "in-print-publications" }
      }
    ) {
      ...Publications
    }
    authorImage: file(
      relativePath: { regex: $directory }
      name: { eq: "in-print-michael-goldberg" }
    ) {
      ...ContentWidthImage
    }
    authorImageBook: file(
      relativePath: { regex: $directory }
      name: { eq: "in-print-michael-goldberg-book" }
    ) {
      ...MarginaliaImage
    }
  }
`;

export default DepartmentContent;
