import './Publications.scss';

import React from 'react';
import { graphql } from 'gatsby';
import { groupBy } from 'lodash';

import * as Core from 'components/core';

interface Publication {
  id: string;
  title: string;
  author: string;
  authorGroup: 'Faculty Ink' | 'Alumni Books';
  publisher: string;
  link: string | null;
  imageHeight: number;
  image?: {
    sizes: {
      thumb: {
        width: number;
        src: string;
        srcSet: string;
      };
    };
  };
}

export interface Props {
  publications: {
    nodes: {
      publications: Publication[];
    }[];
  };
}

const Publications: React.FC<Props> = (props) => {
  const { publications } = props.publications.nodes[0];
  const groups: { [authorGroup: string]: Publication[] } = groupBy(
    publications,
    (p) => p.authorGroup
  );

  return (
    <div className="Publications">
      {Object.keys(groups).map((groupName) => (
        <div key={groupName}>
          <Core.SecondaryHeading>{groupName}</Core.SecondaryHeading>
          <div className="Publications-grid">
            {groups[groupName].map((publication) => (
              <div
                key={publication.id}
                className="Publications-grid-publication"
              >
                {publication.image && (
                  <div className="Publications-grid-publication-imageWrapper">
                    <img
                      className="Publications-grid-publication-image"
                      srcSet={publication.image.sizes.thumb.src}
                      sizes={`${publication.image.sizes.thumb.width}px`}
                      style={{
                        height: `${publication.imageHeight * 100}%`
                      }}
                    />
                  </div>
                )}
                <div className="Publications-grid-publication-description">
                  <div className="Publications-grid-publication-description-title">
                    {publication.title}
                  </div>
                  <div>{publication.publisher}</div>
                  <div>{publication.author}</div>
                  {publication.link && (
                    <a
                      href={publication.link}
                      className="Publications-grid-publication-description-link"
                    >
                      Buy
                    </a>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export const publicationFragment = graphql`
  fragment Publication on InPrintPublicationsYaml {
    id
    title
    author
    authorGroup
    publisher
    link
    imageHeight
    image {
      sizes: childImageSharp {
        # Book covers contain solid colors and text which looks very bad
        # when compressed more
        thumb: fixed(height: 200, quality: 95) {
          width
          src
          srcSet
        }
      }
    }
  }
`;

export const publicationsFragment = graphql`
  fragment Publications on FileConnection {
    nodes {
      publications: childrenInPrintPublicationsYaml {
        ...Publication
      }
    }
  }
`;

export default Publications;
